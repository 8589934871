/**
 * 运费相关api
 */
import request from "@/utils/request";
import { helper } from "echarts";

/**
 * 获取运费详情
 * @param params
 */
export function getTransPort(params) {
  return request({
    url: '/seller/shops/goodsPostage',
    method: 'get',
    params
  })
}

/**
 * 保存运费设置
 * @param params
 */
export function saveTransPort(params) {
  return request({
    url: '/seller/shops/goodsPostage',
    method: 'put',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
